import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import AuthContext from "../context/AuthContext/AuthContext";
import { useContext, useEffect, useState } from "react";
const AdminLayout = () => {
  const [theme,setTheme]=useState(null);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(()=>{
    if(window.matchMedia("(prefers-color-scheme: dark)").matches){
      setTheme("dark");
    }else{
      setTheme("light");
    }
  },[])
  useEffect(()=>{
    if(theme === "dark") {
      document.documentElement.classList.add("dark") 
    }else{ 
      document.documentElement.classList.remove("dark")
  }
  },[theme])

  const handleThemeSwitch = () =>{
    setTheme(theme === "dark" ? "light" : "dark")
  }
  return (
    <div className="flex justify-center align-center flex-col">
      <NavBar ToggleBtnVal={theme} setToggleBtnVal={handleThemeSwitch}/>
          {console.log(authContext.isLoggedIn,"<<<")}
        {authContext.isLoggedIn ? <Outlet />  : navigate("/admin/login")}
    </div>
  )
}

export default AdminLayout