import LetsConnectButton from "../common/LetsConnectButton/LetsConnectButton";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MdOutlineLightMode } from "react-icons/md";
import { MdOutlineDarkMode } from "react-icons/md";
import { appName } from "../config/AppConfigs";
import AuthContext from "../context/AuthContext/AuthContext";
import LOGO from "../assets/Images/XZ_LOGO.svg"
const NavBar = ({ ToggleBtnVal, setToggleBtnVal, OpenContactModal }) => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const navigations = [
    { name: "Home", to: "/" },
    { name: "Services", to: "#services" },
    // { name: "Our Team", to: "#ourTeam" },
    { name: "About", to: "#about" },
  ];
  const admin_navs = [
    { name: "Dashboard", to: "/admin/dashboard" },
    { name: "User Requests", to: "/admin/dashboard/user/requests" },
  ];
  const login = { name: "Login", to: "/admin/login" };

  const [isTop, setIsTop] = useState(true);

  const [adminNavs, setAdminNavs] = useState();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setIsTop(!isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    authContext.isLoggedIn
      ? setAdminNavs(adminNavs)
      : setAdminNavs([]) && navigate("/admin/login");
  }, [authContext.isLoggedIn]);
  return (
    <nav
      className={` border-gray-200 mb-8 sticky top-0 z-10 ${
        isTop
          ? "bg-white dark:bg-gray-900 "
          : "bg-white dark:bg-gray-900 md:bg-white/75 md:dark:bg-gray-900/75"
      }`}
    >
      <div className="flex flex-wrap items-center justify-between mx-auto py-4 px-14">
        <NavLink
          to={location.pathname.startsWith("/admin") && authContext.isLoggedIn ? "/admin/dashboard" : "/"}
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src={LOGO}
            className="h-8 dark:bg-white rounded-full"
            alt="Flowbite Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            {appName}
          </span>
        </NavLink>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-inherit dark:bg-gray-800 md:dark:bg-inherit dark:border-gray-700">
            {location.pathname.startsWith("/admin") && authContext.isLoggedIn &&  admin_navs.map(({ name, to }) => (
                    <li>
                      {to.startsWith("#") ? (
                        <a
                          href={to}
                          className={`block py-2 px-3  ${
                            to === location.pathname
                              ? "text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                              : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                          }`}
                          aria-current="page"
                        >
                          {name}
                        </a>
                      ) : (
                        <NavLink
                          to={to}
                          className={`block py-2 px-3 ${
                            to === location.pathname
                              ? "text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                              : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                          } `}
                        >
                          {name}
                        </NavLink>
                      )}
                    </li>
                  ))}
              {!location.pathname.startsWith("/admin") &&
                navigations.map(({ name, to }) => (
                  <li>
                    {to.startsWith("#") ? (
                      <a
                        href={to}
                        className={`block py-2 px-3  ${
                          to === location.pathname
                            ? "text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                            : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                        }`}
                        aria-current="page"
                      >
                        {name}
                      </a>
                    ) : (
                      <NavLink
                        to={to}
                        className={`block py-2 px-3 ${
                          to === location.pathname
                            ? "text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                            : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                        } `}
                      >
                        {name}
                      </NavLink>
                    )}
                  </li>
                ))
              }
            {!location.pathname.startsWith("/admin") && (
              <li className="dark:text-white">
                <button onClick={OpenContactModal}>Contact Us</button>
              </li>
            )}
            <li>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={ToggleBtnVal === "dark"}
                  value={ToggleBtnVal}
                  onChange={setToggleBtnVal}
                  class="sr-only peer absolute"
                />
                <div
                  class={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
                >
                  {ToggleBtnVal === "dark" ? (
                    <MdOutlineDarkMode
                      className={`relative top-1 -right-6 z-10`}
                    />
                  ) : (
                    <MdOutlineLightMode
                      size={16}
                      className={`relative top-1 left-1 z-10`}
                    />
                  )}
                </div>
                {/* <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span> */}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
