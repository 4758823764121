import React from 'react'
import AboutImg from '../assets/Images/Img4.jpeg'
const About = () => {
  return (
    <div id='about' className="flex flex-col bg-bg-blacks dark:bg-gray-900 mx-16 my-7 px-5 py-4 w-11/12 rounded-lg text-white">
       <div className='mb-3'>
        <h1 className="text-3xl font-bold text-white">About</h1>
      </div>
      <div className='flex flex-col gap-2 md:gap-0 md:flex-row'>
        <img src={AboutImg} alt="AboutImg" className='w-full md:w-1/2 h-1/2  rounded-lg'/>
<div className='px-4 flex flex-col gap-3 justify-between mb-3 md:w-1/2'>
  <h1 className='text-2xl font-semibold mb-2 border-white w-fit'>
    Introduction
  </h1>
    <p className='w-full md:w-full mx-auto my-auto text-justify'>
    Xzistech is a dynamic IT and digital marketing company dedicated to providing innovative solutions and strategies to businesses worldwide. With a team of experienced professionals and a commitment to excellence, we specialize in delivering comprehensive IT services and cutting-edge digital marketing solutions tailored to our clients' unique needs. 
    </p>
    <h1 className='text-2xl font-semibold mb-2 border-white w-fit'>Mission</h1>
    <p className='w-full md:w-full mx-auto my-auto text-justify'>
       

At Xzistech, our mission is to empower businesses with technology and marketing solutions that drive growth, innovation, and success in the digital age. We strive to deliver exceptional value through creativity, strategic thinking, and unwavering dedication to customer satisfaction. 
    </p>
    <h1 className='text-2xl font-semibold mb-2 border-white w-fit'>Values</h1>
    <ul className='flex flex-col gap-4 justify-between align-center text-justify'>
          <li>We embrace innovation and creativity, continuously exploring new ideas and technologies to deliver cutting-edge solutions. </li>
          <li>We conduct business with honesty, transparency, and integrity, earning the trust and respect of our clients, partners, and stakeholders. </li>
          <li>We believe in the power of collaboration, working closely with clients to understand their needs and develop customized solutions that deliver measurable results. </li>
          <li>We are committed to excellence in everything we do, maintaining the highest standards of quality, professionalism, and customer service. </li>
          <li>We adapt quickly to changing market trends and technologies, staying agile and responsive to meet the evolving needs of our clients and the industry.</li>
        </ul>
</div>
      </div>
    </div>
  )
}

export default About