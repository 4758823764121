import React, { useState } from 'react';

const Tooltip = ({ text, children,topHeight }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      {children}
      {showTooltip && (
     <div className={`absolute z-10 left-1/2 transform -translate-x-1/2 -translate-y-[55px] bg-gray-900 text-white px-2 py-1 rounded-lg text-sm shadow-lg whitespace-nowrap overflow-hidden`}>
     {text}
   </div>
      )}
    </div>
  );
};
export default Tooltip;
