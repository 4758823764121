import React, { useState, useEffect } from 'react'
import { getServicesData,getAllMainServices,deleteService } from '../../backend/APIS';
import AddServiceModal from '../../models/Admin/AddServiceModal';
import PreviewMainServiceModal from '../../models/Admin/PreviewMainServiceModal';
import UpdateMainServiceModal from '../../models/Admin/UpdateMainServiceModal';
import DeleteConfirmationModal from '../../models/Admin/DeleteConfirmationModal';
import Tooltip from '../../common/Tooltip';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { toast } from 'react-toastify';
const Home = () => {
  const [serviceData, setServiceData] = useState([]);
  const [mainServiceData,updateMainServiceData]=useState([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openAddServiceModal,setOpenAddServiceModal] = useState(false);
  const [openPreviewMainServiceModal,setPreviewMainServiceModal] = useState(false);
  const [openUpdateServiceModal,setUpdateServiceModal] = useState(false);
  const [serviceModalValue,setServiceModalValue]=useState({});
  const [openDeleteConfirmationModal,setDeleteConfirmationModal] = useState(false);
  const [isDeleteService,setIsDeleteService] = useState(false);
  const [deletedItem,setDeletedItem] = useState({});
  useEffect(() => {
    getServiceData();
  }, [])
  const getServiceData = () => {
    getServicesData().then((response) => {
      console.log(response.data);
      setServiceData(response.data);
      setFilteredData(response.data);
    }).catch((error) => {
      console.log("Error:", error);
    })
    getAllMainServices().then((response)=>{
      console.log(response.data);
      updateMainServiceData(response.data);
    }).catch((error) => {
      console.log("Error:", error);
    })
  }
  useEffect(() => {
    if (search.trim() !== '') {
      let searchedData = serviceData.filter((service) => service.serviceTitle.toLowerCase().includes(search.toLowerCase()) || service.serviceContent.toLowerCase().includes(search.toLowerCase()));
      setFilteredData(searchedData);
    } else {
      setFilteredData(serviceData);
    }
  }, [search, serviceData])
  const openModal=()=>{
    setUpdateServiceModal(true);
  }
  const closeModal = () => {
    setUpdateServiceModal(false);
    getServiceData();
   };
  const openPreviewServiceModal=()=>{
    setPreviewMainServiceModal(true);
  }
  const closePreviewServiceModal=()=>{
    setPreviewMainServiceModal(false);
  }
  const openAddServicModal=(item)=>{
    setOpenAddServiceModal(true);
    setServiceModalValue(item);
  }
  const closeAddServicModal=()=>{
    setOpenAddServiceModal(false);
  }
  const handleUpdateService=(item)=>{
console.log("item:",item);
item.btnName="Update";
item.title="Update Service";
openAddServicModal(item);
  }
  const closeDeleteConfirmationModal=(value)=>{
    setDeleteConfirmationModal(false);
  }
const handleGetDeleteNotification=(value)=>{
  console.log("deleteConfirmation",value,deletedItem);
  if(value){
    setIsDeleteService(value);
    deleteService(deletedItem._id).then((response)=>{
      if(response.status===200){
        closeDeleteConfirmationModal();
        getServiceData(); 
      console.log("response:",response,response.status);
      toast.success(`🦄 ${deletedItem.serviceTitle} deleted successfully!`);
      }else{
        toast.error(`🦄 Failed to delete ${deletedItem.serviceTitle}!`);
      }
    }).catch((error)=>{
      console.log(error);
      toast.error(`🦄 Failed to delete ${deletedItem.serviceTitle}!`);
    })
  }
}
  const handleDeleteService=(item)=>{
    setDeleteConfirmationModal(true);
    setDeletedItem(item);
    // if(isDeleteService){
    //   console.log("item:",item,item._id);
    //       }
  }
  const handleGetNotifications=(value)=>{
    if(value){
      getServiceData(); 
    }
  }
  return (
    <div className="flex flex-col mx-16 w-11/12">
      {openAddServiceModal && <AddServiceModal openModel={openAddServiceModal} closeModal={closeAddServicModal} serviceModalValue={serviceModalValue} handleGetNotifications={handleGetNotifications}/>}
      {openPreviewMainServiceModal && <PreviewMainServiceModal openModel={openPreviewMainServiceModal} closeModal={closePreviewServiceModal}/>}
      {openUpdateServiceModal && <UpdateMainServiceModal openModel={openUpdateServiceModal} closeModal={closeModal} handleGetNotifications={handleGetNotifications}/>}
      {openDeleteConfirmationModal && <DeleteConfirmationModal openModel={openDeleteConfirmationModal} closeModal={closeDeleteConfirmationModal} deletedItem={deletedItem} handleGetDeleteNotification={handleGetDeleteNotification}/>}
      <div className='text-right my-2'>
        <button onClick={()=>openAddServicModal({btnName:"Add",title:"Add Service"})} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 w-full sm:w-auto">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 w-full sm:w-auto">
            Add Service
          </span>
        </button>
        <button onClick={openModal} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 w-full sm:w-auto">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 w-full sm:w-auto">
            Update Main Service
          </span>
        </button>
        <button onClick={openPreviewServiceModal} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 w-full sm:w-auto">
<span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0 w-full sm:w-auto">
Preview Main Service
</span>
</button>
      </div>
      <div className="max-w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">
        <div className="py-2 px-4 flex justify-between items-center bg-white dark:bg-gray-900 flex-wrap">
          <span className="text-xl ml-2 font-semibold text-gray-500 dark:text-white">Total:{serviceData.length}</span>
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="text" value={search} onChange={(event) => setSearch(event.target.value)} id="table-search" className="block pt-2 pb-2 ps-10 text-sm text-gray-900 border rounded-lg md:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search here..." />
          </div>
        </div>
        {mainServiceData && mainServiceData.length>0 &&<div className='flex mb-3'>
        <h1 className='py-1 px-6'><span className='text-grey dark:text-white'>{mainServiceData[0].mainServiceTitle}</span></h1>
        <h1 className='py-1 px-6 dark:text-white'><span>{mainServiceData[0].mainServiceContent}</span></h1>
        </div>}
        {/* table code */}
        {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-24">
                  Service Title
                </th>
                <th scope="col" className="px-6 py-3 w-1/2">
                  Service Content
                </th>
                <th scope="col" className="px-6 py-3">
                  Service Image
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <h1 className='w-full text-lg text-center text-navName py-2'>Data Not Found</h1>
                  </td>
                </tr>
              )}
              {filteredData.length > 0 && filteredData.map((item, index) => {
                return <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="w-24 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.serviceTitle}
                  </th>
                  <td className="px-6 py-4 dark:text-white text-justify w-1/2">
                    {item.serviceContent}
                  </td>
                  <td className="px-6 py-4 dark:text-white">
                    
                    <img className='w-40 h-auto rounded-lg' src={item.serviceImage} alt={`serviceImage${index}`} />
                  </td>
                  <td className="px-6 py-4 text-right">
                  <Tooltip text="Edit" topHeight="55px">
                    <button onClick={()=>handleUpdateService(item)}><FaRegEdit size="20" className='text-text-primary dark:text-white'/></button>
                    </Tooltip>
                    <Tooltip text="Delete" topHeight="55px">
                    <button onClick={()=>handleDeleteService(item)} className='ml-2'><MdDelete size="20" className='text-navName'/></button>
                    </Tooltip>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div> */}
        {/* from here */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
  {/* Desktop Table */}
  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 hidden sm:table">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" className="px-6 py-3 w-24">Service Title</th>
        <th scope="col" className="px-6 py-3 w-1/2">Service Content</th>
        <th scope="col" className="px-6 py-3">Service Image</th>
        <th scope="col" className="px-6 py-3"><span className="sr-only">Edit</span></th>
      </tr>
    </thead>
    <tbody>
      {filteredData.length === 0 && (
        <tr>
          <td colSpan="4">
            <h1 className="w-full text-lg text-center text-navName py-2">Data Not Found</h1>
          </td>
        </tr>
      )}
      {filteredData.length > 0 && filteredData.map((item, index) => (
        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
          <th scope="row" className="w-24 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {item.serviceTitle}
          </th>
          <td className="px-6 py-4 dark:text-white text-justify w-1/2">
            {item.serviceContent}
          </td>
          <td className="px-6 py-4 dark:text-white">
            <img className="w-40 h-auto rounded-lg" src={item.serviceImage} alt={`serviceImage${index}`} />
          </td>
          <td className="px-6 py-4 text-right">
            <Tooltip text="Edit" topHeight="55px">
              <button onClick={() => handleUpdateService(item)}><FaRegEdit size="20" className="text-text-primary dark:text-white" /></button>
            </Tooltip>
            <Tooltip text="Delete" topHeight="55px">
              <button onClick={() => handleDeleteService(item)} className="ml-2"><MdDelete size="20" className="text-navName" /></button>
            </Tooltip>
          </td>
        </tr>
      ))}
    </tbody>
  </table>

  {/* Mobile View */}
  <div className="block sm:hidden">
    {filteredData.length === 0 && (
      <div className="text-center py-2 text-lg text-navName">
        <h1>Data Not Found</h1>
      </div>
    )}
    {filteredData.length > 0 && filteredData.map((item, index) => (
      <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow mb-4 p-4 space-y-4">
        <div className="text-lg font-medium text-gray-900 dark:text-white">
          {item.serviceTitle}
        </div>
        <div className="dark:text-white text-justify">
          {item.serviceContent}
        </div>
        <div className="w-full">
          <img className="w-full h-auto rounded-lg" src={item.serviceImage} alt={`serviceImage${index}`} />
        </div>
        <div className="flex justify-end space-x-2">
          <Tooltip text="Edit" topHeight="55px">
            <button onClick={() => handleUpdateService(item)}><FaRegEdit size="20" className="text-text-primary dark:text-white" /></button>
          </Tooltip>
          <Tooltip text="Delete" topHeight="55px">
            <button onClick={() => handleDeleteService(item)} className="ml-2"><MdDelete size="20" className="text-navName" /></button>
          </Tooltip>
        </div>
      </div>
    ))}
  </div>
</div>

        {/* till here */}
        {/* table code till here */}
      </div>

    </div>
  )
}

export default Home