import React, { useContext, useState } from 'react'
import AuthContext from '../../context/AuthContext/AuthContext'
import { submitLoginDetails } from '../../backend/APIS';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
const Login = () => {
  const [passInputType, setPassInputType] = useState("password");
  const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const handleLogin = (e) => {
      setPassInputType("password");
      e.preventDefault();
const body = {
  email:e.target.email.value,
  password:e.target.password.value
}
console.log(body," <<in")
        // submitLoginDetails(body).then(res=>res.json()).then(data=>authContext.login() && navigate('/admin/dashboard') && toast.success('🦄'+data.responseDesc,)).catch((error) => {
        //   toast.error('🦄 Failed to login!',);
        //   console.error(error);
        // })
        submitLoginDetails(body).then((response)=>{
          console.log("response:",response);
          console.log("response1:",response.data);
          if(response.data && response.data.responseCode===0){
            authContext.login()
            navigate('/admin/dashboard');
            toast.success('🦄'+response.data.responseDesc)
          }else if(response.data && response.data.responseCode!==0){
            toast.error('🦄'+response.data.responseDesc);
          }else{
            console.log(response.message)
            toast.error('🦄'+response.message);
          }
        })
    }


  return (
    <div class="font-[sans-serif] text-[#333] bg-white dark:bg-inherit flex items-center justify-center p-4">
      <div class="shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)] dark:shadow-[inset_0_2px_16px_-3px_rgba(6,81,237,0.3)] max-w-6xl rounded-md p-6">
        <div class="grid md:grid-cols-2 items-center gap-8">
          <div class="max-md:order-1">
            <img src="https://readymadeui.com/signin-image.webp" class="lg:w-11/12 w-full object-cover" alt="loginImage" />
          </div>
          <form class="max-w-md w-full mx-auto" onSubmit={(e)=>handleLogin(e)}>
            <div class="mb-12">
              <h3 class="text-4xl font-extrabold text-blue-600">Sign in</h3>
            </div>
            <div>
              <div class="relative flex items-center">
                <input name="email" type="text" required class="w-full text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none" placeholder="Enter email" />
                <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-2" viewBox="0 0 682.667 682.667">
                  <defs>
                    <clipPath id="a" clipPathUnits="userSpaceOnUse">
                      <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#a)" transform="matrix(1.33 0 0 -1.33 0 682.667)">
                    <path fill="none" stroke-miterlimit="10" stroke-width="40" d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z" data-original="#000000"></path>
                    <path d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z" data-original="#000000"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div class="mt-8">
              <div class="relative flex items-center">
                <input name="password" type={passInputType} required class="w-full text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none" placeholder="Enter password" />
                {passInputType === "password" && <RiEyeLine onClick={() => setPassInputType("text")} className="w-[18px] h-[18px] absolute right-2 cursor-pointer text-[#bbb]" />}
                {passInputType === "text" && <RiEyeCloseLine onClick={() => setPassInputType("password")} className="w-[18px] h-[18px] absolute right-2 cursor-pointer text-[#bbb]" />}
              </div>
            </div>
            {/* <div class="flex items-center justify-between gap-2 mt-6">
              <div class="flex items-center">
                <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                <label for="remember-me" class="dark:text-white ml-3 block text-sm">
                  Remember me
                </label>
              </div>
              <div>
                <a href="jajvascript:void(0);" class="text-blue-600 text-sm hover:underline">
                  Forgot Password?
                </a>
              </div>
            </div> */}
            <div class="mt-12">
              <input type="submit" value="Sign in" className="w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none cursor-pointer"/>
              {/* <p class="dark:text-white text-sm text-center mt-8">Don't have an account <a href="javascript:void(0);" class="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap">Register here</a></p> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login